import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import * as Schema from '@sellermodules/storeintegration/services/graphql/schema';

const context = {
    context: { request: 'internal' },
};

const fetchPolicy = {
    fetchPolicy: 'cache-and-network',
};

const fetchPolicyCache = {
    fetchPolicy: 'no-cache',
};

export const getMarketplaceList = (options) => useQuery(Schema.getMarketplaceList, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const getSellerMpCredentials = (options) => useLazyQuery(Schema.getSellerMpCredentials, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const getSellerMpCredentialsQuery = (options) => useQuery(Schema.getSellerMpCredentials, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const getMarketplaceDefaultShippingMethods = (options) => useLazyQuery(Schema.getMarketplaceDefaultShippingMethods, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const connectSellerMp = (options) => useMutation(Schema.connectSellerMp, {
    ...options,
    ...context,
});

export const requestSellerMarketplaceIntegration = (options) => useMutation(Schema.requestSellerMarketplaceIntegration, {
    ...options,
    ...context,
});

export const getWebstoreList = (options) => useQuery(Schema.getWebstoreList, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const getWebstoreConfiguration = (options) => useQuery(Schema.getWebstoreConfiguration, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const createWebstore = (options) => useMutation(Schema.createWebstore, {
    ...options,
    ...context,
});

export const registerBrandMp = (options) => useMutation(Schema.registerBrandMp, {
    ...options,
    ...context,
});

export const integrateKatalisAccount = (options) => useMutation(Schema.integrateKatalisAccount, {
    ...options,
    ...context,
});

export const disconnectKatalisAccount = (options) => useMutation(Schema.disconnectKatalisAccount, {
    ...options,
    ...context,
});

export const getKatalisImageBackgroundList = (options) => useLazyQuery(Schema.getKatalisImageBackgroundList, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const getKatalisCreditBalance = (options) => useLazyQuery(Schema.getKatalisCreditBalance, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const generateKatalisCopy = (options) => useMutation(Schema.generateKatalisCopy, {
    ...options,
    ...context,
});

export const getGeneratedKatalisImagesbySKU = (options) => useLazyQuery(Schema.getGeneratedKatalisImagesbySKU, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const generateKatalisImages = (options) => useMutation(Schema.generateKatalisImages, {
    ...options,
    ...context,
});

export const setDeletedKatalisImages = (options) => useMutation(Schema.setDeletedKatalisImages, {
    ...options,
    ...context,
});

export const uploadTmpImage = (options) => useMutation(Schema.uploadTmpImage, {
    ...options,
    ...context,
});

export const registerKatalisAccount = (options) => useMutation(Schema.registerKatalisAccount, {
    ...options,
    ...context,
});

export const getKatalisAccount = (options) => useLazyQuery(Schema.getKatalisAccount, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const getIsShopifyPublicAccount = (options) => useLazyQuery(Schema.getIsShopifyPublicAccount, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const getAddonsList = (options) => useLazyQuery(Schema.getAddonsList, {
    ...options,
    ...context,
    ...fetchPolicyCache,
});

export const updateIntegrationLocation = (options) => useMutation(Schema.updateIntegrationLocation, {
    ...options,
    ...context,
});

export const getAuthUrlAddonChamber = (options) => useLazyQuery(Schema.getAuthUrlAddonChamber, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const setCredentialAddonChamber = (options) => useMutation(Schema.setCredentialAddonChamber, {
    ...options,
    ...context,
});

export const setDisconnectAddonChamber = (options) => useMutation(Schema.setDisconnectAddonChamber, {
    ...options,
    ...context,
});

export const getOrderQueueStatusChamber = (options) => useLazyQuery(Schema.getOrderQueueStatusChamber, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const reQueueOrderChamber = (options) => useMutation(Schema.reQueueOrderChamber, {
    ...options,
    ...context,
});

export const addQueueOrderChamber = (options) => useMutation(Schema.addQueueOrderChamber, {
    ...options,
    ...context,
});

export default {
    getMarketplaceList,
    getSellerMpCredentials,
    getSellerMpCredentialsQuery,
    getMarketplaceDefaultShippingMethods,
    connectSellerMp,
    requestSellerMarketplaceIntegration,
    getWebstoreList,
    getWebstoreConfiguration,
    createWebstore,
    registerBrandMp,
    integrateKatalisAccount,
    disconnectKatalisAccount,
    getKatalisImageBackgroundList,
    getKatalisCreditBalance,
    generateKatalisCopy,
    getGeneratedKatalisImagesbySKU,
    generateKatalisImages,
    setDeletedKatalisImages,
    uploadTmpImage,
    registerKatalisAccount,
    getKatalisAccount,
    getIsShopifyPublicAccount,
    getAddonsList,
    updateIntegrationLocation,
    getAuthUrlAddonChamber,
    setCredentialAddonChamber,
    setDisconnectAddonChamber,
    getOrderQueueStatusChamber,
    reQueueOrderChamber,
    addQueueOrderChamber,
};

/* eslint-disable */
// eslint-disable-next-line object-curly-newline
/* eslint-disable max-len */
import {
    ApolloClient, HttpLink, InMemoryCache, from, ApolloLink,
} from '@apollo/client';
import { RetryLink } from 'apollo-link-retry';
import fetch from 'isomorphic-unfetch';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { removeIsLoginFlagging } from '@helper_auth';
import { getAppEnv } from '@helpers/env';
import getConfig from 'next/config';
import firebase from '@lib_firebase/index';
import Cookies from 'js-cookie';

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
        __schema: {
            types: [],
        },
    },
});

const appEnv = getAppEnv();
const { publicRuntimeConfig } = getConfig();

const uri = publicRuntimeConfig.GRAPHQL_URL;

const uriMultipleName = publicRuntimeConfig.GRAPHQL_MULTIPLE_NAME;
const uriMultiple = publicRuntimeConfig.GRAPHQL_MULTIPLE_URL;

const uriPagesName = publicRuntimeConfig.GRAPHQL_SWIFTPAGE_NAME;
const uriPages = publicRuntimeConfig.GRAPHQL_SWIFTPAGE_URL;

const uriAdsName = publicRuntimeConfig.GRAPHQL_ADS_NAME;
const uriAds = publicRuntimeConfig.GRAPHQL_ADS_URL;

const uriLoyaltyName = publicRuntimeConfig.GRAPHQL_SWIFTLOYALTY_NAME;
const uriLoyalty = publicRuntimeConfig.GRAPHQL_SWIFTLOYALTY_URL;

const uriInternal = `${publicRuntimeConfig.BASE_URL}/graphql`;
// handle if token expired
const logoutLink = onError((err) => {
    const { graphQLErrors, networkError } = err;
    if (networkError && typeof window !== 'undefined' && graphQLErrors && graphQLErrors.length > 0 && graphQLErrors[0].status > 500) {
        window.location.href = '/maintenance';
    } else if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].extensions && graphQLErrors[0].extensions.category === 'graphql-seller-authorization') {
        if (window.location.pathname !== '/seller/unauthorized') {
            window.location.href = '/seller/unauthorized';
        }
    } else if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].status === 401 && typeof window !== 'undefined') {
        try {
            firebase.auth().signOut();
            removeIsLoginFlagging();
            if (!['/seller/register', '/authorize/app'].includes(window?.location?.pathname)) {
                window.location.href = '/login';
            }
        } catch (e) { }
    } else if (networkError && networkError.statusCode === 413 && typeof window !== 'undefined') {
        networkError.message = 'Your file is too large. Please choose smaller file size.';
    }
});

const link = new RetryLink().split(
    (operation) => operation.getContext().request === 'internal',
    new HttpLink({
        uri: uriInternal, // Server URL (must be absolute)
        credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
        fetch,
    }),
    new RetryLink().split(
        (operation) => operation.getContext().request === `${uriMultipleName}`,
        new HttpLink({
            uri: uriMultiple, // Server URL (must be absolute)
            credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
            fetch,
            useGETForQueries: false,
        }),
        new RetryLink().split(
            (operation) => operation.getContext().request === `${uriAdsName}`,
            new HttpLink({
                uri: uriAds, // Server URL (must be absolute)
                credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
                fetch,
                useGETForQueries: true,
            }),
            new RetryLink().split(
                (operation) => operation.getContext().request === `${uriPagesName}`,
                new HttpLink({
                    uri: uriPages, // Server URL (must be absolute)
                    credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
                    fetch,
                    useGETForQueries: true,
                }),
                new RetryLink().split(
                    (operation) => operation.getContext().request === `${uriLoyaltyName}`,
                    new HttpLink({
                        uri: uriLoyalty, // Server URL (must be absolute)
                        credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
                        fetch,
                        useGETForQueries: true,
                    }),
                    new HttpLink({
                        uri, // Server URL (must be absolute)
                        credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
                        fetch,
                        useGETForQueries: true,
                    }),
                ),
            ),
        ),
    ),
);

// const link = new RetryLink().split(
//     (operation) => operation.getContext().request === 'internal',
//     new HttpLink({
//         uri: uriInternal, // Server URL (must be absolute)
//         credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
//         fetch,
//     }),
//     new RetryLink().split(
//         (operation) => operation.getContext().request === chatContextName,
//         new HttpLink({
//             uri: chatUri,
//             credentials: 'same-origin',
//             fetch,
//             useGETForQueries: true,
//         }),
//         new HttpLink({
//             uri, // Server URL (must be absolute)
//             credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
//             fetch,
//             useGETForQueries: true,
//         }),
//     ),
// );

export default function createApolloClient(initialState, ctx) {
    // The `ctx` (NextPageContext) will only be present on the server.
    // use it to extract auth headers (ctx.req) or similar.
    const middlewareHeader = new ApolloLink((operation, forward) => {
        const additionalHeader = {};
        operation.setContext((req) => {
            const { headers = {} } = req;

            if (operation.getContext().request
                && (operation.getContext().request === uriPagesName
                || operation.getContext().request === uriAdsName
                || operation.getContext().request === uriLoyaltyName
                )) {
                let cdt = Cookies.get('cdt');
                if (cdt){
                    cdt = JSON.parse(cdt);
                }
                additionalHeader.vendorcode = cdt?.customer_company_code || '2067';
                additionalHeader.email = cdt?.email || '';
            }

            return {
                headers: {
                    ...headers,
                    ...additionalHeader,
                },
            };
        });

        return forward(operation);
    });
    return new ApolloClient({
        ssrMode: Boolean(ctx),
        link: from([
            middlewareHeader,
            logoutLink,
            link,
        ]),
        cache: new InMemoryCache({ fragmentMatcher }).restore(initialState),
        // eslint-disable-next-line no-underscore-dangle
        connectToDevTools: typeof window !== 'undefined' && window.__APOLLO_CLIENT__
            && (appEnv === 'local'),
        resolvers: {},
    });
}

const crypto = require('crypto');
const getConfig = require('next/config');

const { publicRuntimeConfig } = getConfig.default();

const encrypt = (text) => {
    const cipher = crypto.createCipheriv(
        publicRuntimeConfig.ENCRYPTION_ALGORITHM,
        publicRuntimeConfig.ENCRYPTION_KEY,
        publicRuntimeConfig.ENCRYPTION_KEY.substr(0, 16),
    );

    let crypted = cipher.update(text, 'utf8', 'base64');
    crypted += cipher.final('base64');
    return crypted;
};

const decrypt = (text) => {
    const decipher = crypto.createDecipheriv(
        publicRuntimeConfig.ENCRYPTION_ALGORITHM,
        publicRuntimeConfig.ENCRYPTION_KEY,
        publicRuntimeConfig.ENCRYPTION_KEY.substr(0, 16),
    );

    let dec = decipher.update(text, 'base64', 'utf8');
    dec += decipher.final('utf8');
    return dec;
};

module.exports = {
    encrypt,
    decrypt,
};
